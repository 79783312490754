import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import pic1 from '../images/weighBeltFeeder1.png'
import pic2 from '../images/fishing.webp'
import pic6 from '../images/ticker4edited.png'
import pic8 from '../images/silos.png'
import pic9 from '../images/partners.png'

const Slide = (props) => (
    <div className = "slideshow mx-3 py-5">
        <Carousel className='carousel' interval={3600} keyboard={false} pauseonhover="true">
            <Carousel.Item>
                <img className="d-block mx-auto slide" src={pic8} alt="pic 1"/>
            </Carousel.Item>
            <Carousel.Item>
                <img className="d-block mx-auto slide" src={pic1} alt="pic 1"/>
            </Carousel.Item>
            <Carousel.Item>
                <img className="d-block mx-auto slide" src={pic9} alt="pic 1"/>
            </Carousel.Item>
            <Carousel.Item>
                <img className="d-block mx-auto slide" src={pic6} alt="pic 1"/>
            </Carousel.Item>
            <Carousel.Item>
                <img className="d-block mx-auto slide1" src={pic2} alt= "pic 2"/>
                <Carousel.Caption>
                    <h4 className = 'capt'>Have questions? Drop us a line!</h4>
                </Carousel.Caption>
            </Carousel.Item>
        </Carousel> 
        <h3 className="text-center capt" style={{'fontWeight':"bold", "fontStyle": "italic"}}>Designing systems and equipment to fit your process</h3>
    </div>
)

export default Slide