import React from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import ProductNav from './ProductNav'
import Contact from './Contact'
import Product from './Product'






class AllProducts extends React.Component{


    render(){
        return (
            <BrowserRouter basename="/#/AllProducts">
            <div className="row prodpage">
                <div className="col-xl-2 col-lg-3 col-md-12 col-sm-12" >
                    <ProductNav />
                </div>
                <div className = "col-xl-10 col-lg-9 col-md-12 col-sm-12 prodback">
                    <Switch>
                        <Route exact path = "/" render = {()=> <Product  file={'/files/LineCard2024.pdf'}/>} />
                        <Route exact path = "/BulkBagFill" render = {()=> <Product file={'/files/MT Power-Fill Brochure.pdf'} />} />
                        <Route exact path = "/BulkBagDischarge" render = {()=> <Product file={'/files/Material Master® Bulk Bag Discharger Brochure.pdf'} />} />
                        <Route exact path = "/BulkBagCondition" render = {()=> <Product file={'/files/Material-Master®-Bulk-Bag-Conditioner-Brochure.pdf'} />} />
                        <Route exact path = "/DumpStation" render = {()=> <Product file={'/files/MM Drum & Container Discharging Systems.pdf'} />} />
                        <Route exact path = "/BeltScales" render = {()=> <Product file={'/files/Belt-Scale_New_Multi_WP25.pdf'} />} />
                        <Route exact path = "/BeltFeeders" render = {()=> <Product file={'/files/WF-10-Weigh-Belt-Feeder-product-literature.pdf'} />} />
                        <Route exact path = "/VolumetricFeeders" render = {()=> <Product file={'/files/Volumetric-Feeders-product-literature.pdf'} />} />
                        <Route exact path = "/AirLock" render = {()=> <Product file={'/files/Prater-BAV-ROTARY-AIRLOCK-BROCHURE2.pdf'} />} />
                        <Route exact path = "/AirLockQTA" render = {()=> <Product file={'/files/Prater-Rotary-Airlock-Valve-Quick-Take-Apart-QTA.pdf'} />} />
                        <Route exact path = "/AirLockHeavyDuty" render = {()=> <Product file={'/files/Prater-PAV-1416-Heavy-Duty-Rotary-Airlock.pdf'} />} />
                        <Route exact path = "/AirLockAbrasionResist" render = {()=> <Product file={'/files/Prater-Abrasion-Resistant-Airlocks.pdf'} />} />
                        <Route exact path = "/AirLockDustCollector" render = {()=> <Product file={'/files/Prater-Rotary-Airlock-Valve-Dust-Collector-DCS.pdf'} />} />
                        <Route exact path = "/AirClassifiers" render = {()=> <Product file={'/files/AirClassifier.pdf'} />} />
                        <Route exact path = "/AirClassifyingMills" render = {()=> <Product file={'/files/AirClassifyingMill.pdf'} />} />
                        <Route exact path = "/HammerMills" render = {()=> <Product file={'/files/Hammer-MIll.pdf'} />} />
                        <Route exact path = "/MegaMills" render = {()=> <Product file={'/files/MegaMillSpecSheet.pdf'} />} />
                        <Route exact path = "/FineGrinder" render = {()=> <Product file={'/files/Fine Grinder.pdf'} />} />
                        <Route exact path = "/LumpFlakeBreaker" render = {()=> <Product file={'/files/LumpFlake-Breaker.pdf'} />} />
                        <Route exact path = "/CentrifugalSifter" render = {()=> <Product file={'/files/Prater-Rota-Sieve.pdf'} />} />
                        <Route exact path = "/Contact" render = {()=> <Contact />} />
                    </Switch>
                </div>
            </div>
            </BrowserRouter>
        )
    }
}

export default AllProducts;