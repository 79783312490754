import React from 'react';
import { Link } from "react-router-dom";

class ProductNav extends React.Component{

    render(){
        return (
                <div className="text-center product-nav">
                    <div className="prodtop">
                        <div style={{"height": "0.5rem"}}></div>
                        <Link to={{pathname:'/'}}>
                        <h3 className="prodtopcapt">Products</h3>
                        </Link>
                    </div>
                    <div className="prodcat">
                    <Link to={{pathname:"/BulkBagFill"}}>
                        <div className="grey">Bulk Bag Fillers</div>
                    </Link>
                    </div>
                    <div className="prodcat">
                    <Link to={{pathname:"/BulkBagDischarge"}}>
                        <div className="grey">Bulk Bag Dischargers</div>
                    </Link>
                    </div>
                    <div className="prodcat">
                    <Link to={{pathname:"/BulkBagCondition"}}>
                        <div className="grey">Bulk Bag Conditioners</div>
                    </Link>
                    </div>
                    <div className="prodcat">
                    <Link to={{pathname:"/DumpStation"}}>
                        <div className="grey">Container Discharging Systems</div>
                    </Link> 
                    </div>
                    <div className="prodcat">
                    <Link to={{pathname:"/BeltScales"}}>
                        <div className="grey">Belt Scales</div>
                    </Link> 
                    </div>
                    <div className="prodcat">
                    <Link to={{pathname:"/BeltFeeders"}}>
                        <div className="grey">Weigh Belt Feeders</div>
                    </Link> 
                    </div>
                    <div className="prodcat">
                    <Link to={{pathname:"/VolumetricFeeders"}}>
                        <div className="grey">Volumetric Feeders</div>
                    </Link> 
                    </div>
                    <div className="prodcat">
                    <Link to={{pathname:"/AirLock"}}>
                        <div className="grey">Rotary Air Locks</div>
                    </Link> 
                    </div>                    
                    <div className="prodcat">
                    <Link to={{pathname:"/AirLockQTA"}}>
                        <div className="grey">Rotary Air Locks (Quick-Take-Apart)</div>
                    </Link> 
                    </div>                    
                    <div className="prodcat">
                    <Link to={{pathname:"/AirLockHeavyDuty"}}>
                        <div className="grey">Rotary Air Locks (Heavy Duty)</div>
                    </Link> 
                    </div>                    
                    <div className="prodcat">
                    <Link to={{pathname:"/AirLockDustCollector"}}>
                        <div className="grey">Rotary Air Locks (Dust Collector)</div>
                    </Link> 
                    </div>
                    <div className="prodcat">
                    <Link to={{pathname:"/AirClassifiers"}}>
                        <div className="grey">Air Classifiers</div> 
                    </Link> 
                    </div>                    
                    <div className="prodcat">
                    <Link to={{pathname:"/AirClassifyingMills"}}>
                        <div className="grey">Air Classifying Mills</div>
                    </Link> 
                    </div>                    
                    <div className="prodcat">
                    <Link to={{pathname:"/HammerMills"}}>
                        <div className="grey">Hammer Mills</div>
                    </Link> 
                    </div>
                    <div className="prodcat">
                    <Link to={{pathname:"/MegaMills"}}>
                        <div className="grey">Mega Mills</div> 
                    </Link> 
                    </div>                    
                    <div className="prodcat">
                    <Link to={{pathname:"/FineGrinder"}}>
                        <div className="grey">Fine Grinding Mills</div>
                    </Link> 
                    </div>                    
                    <div className="prodcat">
                    <Link to={{pathname:"/LumpFlakeBreaker"}}>
                        <div className="grey">Lump/Flake Breakers</div>
                    </Link> 
                    </div>
                    <div className="prodcat">
                    <Link to={{pathname:"/CentrifugalSifter"}}>
                        <div className="grey">Centrifugal Sifters</div>
                    </Link> 
                    </div>
                </div>
        )
    }
}

export default ProductNav;